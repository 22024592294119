import Player from '@vimeo/player';
import { onWindowResize } from '../utils/windowResize';

function setMediaVideoSize(media, video) {
    const mediaWidth = media.clientWidth;
    const mediaHeight = media.clientHeight;
    const videoHeight = mediaWidth * .5625;

    video.style.width = null;
    video.style.height = `${Math.ceil(videoHeight)}px`;

    if (mediaHeight > videoHeight) {
        const videoWidth = mediaHeight * 1.777777777777778;

        video.style.height = null;
        video.style.width = `${Math.ceil(videoWidth)}px`;
    }
}

export function setupMediaVideos(selector = '[data-action~="media-video"]') {
    const mediaItems = document.querySelectorAll(selector);

    for (let i = 0; i < mediaItems.length; i++) {
        const media = mediaItems[i];
        const mediaIframe = media.hasAttribute('data-video-vimeo-target') ? document.getElementById(media.getAttribute('data-video-vimeo-target')) : media.querySelector('iframe');

        if (mediaIframe) {
            const iframePlayer = new Player(headerVideo);
            iframePlayer.on('play', function() {
                media.classList.add('media--video-playing');
            });

            setMediaVideoSize(media, mediaIframe);
            onWindowResize(() => {
                setMediaVideoSize(media, mediaIframe);
            });
        }
    }
}

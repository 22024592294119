/* GSAP fetched from CDN
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
*/
import { currentWindowWidth, currentWindowHeight, onWindowResize } from './windowResize';
import { vh } from './helpers';

gsap.registerPlugin(ScrollTrigger);

export const transitionEndEventName = detectStyleDeclatationName();
export const animationEndEventName = detectStyleDeclatationName('animation');

/**
 * Detects which prefixed eventName is in CSSStyleDeclaration
 *
 * @param {string} eventTypeName - choose 'transition' or 'animation'
 * @returns {string}
 */
export function detectStyleDeclatationName(eventTypeName = 'transition') {
    const transitions = {
        transition: `${eventTypeName}end`,
        OTransition: `o${eventTypeName}end`,
        MozTransition: `${eventTypeName}end`,
        WebkitTransition: `webkit${eventTypeName}end`
    };

    const el = document.createElement('div');

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return '';
}

export function cssAnimateNewHeight(container, classTarget = container, classOnBoth = false) {
    let newHeight;
    const prevHeight = container.offsetHeight;

    const delayedClose = () => {
        if (classOnBoth) {
            container.classList.remove('open');
            classTarget.classList.remove('open');
        } else {
            classTarget.classList.remove('open');
        }

        classTarget.blur(); // removes focus if its a button
        container.removeEventListener(transitionEndEventName, delayedClose);
    };

    container.removeAttribute('style');

    if (classTarget.classList.contains('open')) {
        newHeight = 0;
        container.addEventListener(transitionEndEventName, delayedClose);

        if (classOnBoth) {
            classTarget.classList.remove('open');
        }
    } else {
        classTarget.classList.add('open');

        if (classOnBoth) {
            container.classList.add('open');
        }

        newHeight = container.offsetHeight;
    }

    container.style.height = `${prevHeight}px`;

    setTimeout(() => {
        container.style.height = `${newHeight}px`;
    }, 10);
}

export function scrollAnimations() {

    const header = document.querySelector('.header');

    if (header) {
        const headImg = header.querySelector('img, .media--fit');

        if (headImg) {
            const isSimple = header.classList.contains('header--simple');
            if (isSimple) {
                gsap.set(headImg, {scale: 1.15});
            }

            gsap.to(headImg, {
                //objectPosition: '50% 80%',
                scale: isSimple ? 1 : 1.15,
                ease: 'none',
                scrollTrigger: {
                    trigger: header,
                    //markers: true,
                    start: isSimple ? '10% top' : 'top top',
                    end: isSimple ? '+=80%' : '+=60%',
                    scrub: 0.5
                },
            });

            headImg.addEventListener('load', () => {
                ScrollTrigger.refresh();
            });
        }
    }

    if (currentWindowWidth >= 1024 && !document.documentElement.classList.contains('old-ie')) {
        const textImages = document.body.querySelectorAll('.text-image--anim');

        for (let ti = 0; ti < textImages.length; ti++) {
            const textImage = textImages[ti];
            const img = textImage.querySelector('img');
            const mediaWrap = textImage.querySelector('.text-image__media-wrap');
            const text = textImage.querySelector('.text-image__text');
            const head = text.querySelector('header');
            const diff = text.offsetHeight - head.offsetHeight;

            const textImgTl = gsap.timeline({
                pause: true
            });

            textImgTl.from(mediaWrap, {
                transformOrigin: '100% 100%',
                scale: 0.6
            })
                .from(img, {
                    transformOrigin: '100% 0',
                    scale: 1.2
                }, '<')
                .to(mediaWrap, {
                    y: diff > 0 ? -diff : 0
                });

            ScrollTrigger.create({
                animation: textImgTl,
                trigger: textImage,
                start: '15% top',
                end: '+=100%',
                scrub: true,
                pin: true,
                anticipatePin: 1
            });

            img.addEventListener('load', () => {
                ScrollTrigger.refresh();
            });
        }
    }

    const textImages = document.body.querySelectorAll('.text-image--full');

    for (let ti = 0; ti < textImages.length; ti++) {
        const textImage = textImages[ti];

        gsap.from(textImage, {
            scrollTrigger: {
                trigger: textImage,
                toggleClass: 'text-image--active',
                start: 'top 60%',
                //markers: true,
            },
        });

        textImage.addEventListener('load', () => {
            ScrollTrigger.refresh();
        });
    }

    onWindowResize(ScrollTrigger.refresh);
}

import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupVideos } from './components/video';
import { initVhUnitOverwrite, debounce } from './utils/helpers';
import { setupAnchorLinkScroll } from './components/anchors';
import DirectionReveal from './vendor/direction-reveal';
import { setupSliders } from './components/slider';
import { currentWindowWidth } from './utils/windowResize';
import { setupFilters } from './components/filter';
import { setupAccordionFilters } from './components/accordion-filter';
import { setupAccordions } from './components/accordion';
import { setupTabs } from './components/tabs';
import { watchFormFields } from './components/form';
import 'wa-mediabox'; // Docs: https://github.com/jirihybek/wa-mediabox
import { scrollAnimations } from './utils/animation';
import { setupLogoSliders } from './modules/logo-slider';
import { setupMediaVideos } from './components/media-videoes';

// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {
        initVhUnitOverwrite();

        setupLazyLoading({offset: 200});

        setupVideos();

        setupAccordionFilters();

        setupFilters();

        setupAccordions();

        setupTabs();

        watchFormFields();

        setupLogoSliders();

        setupMediaVideos();

        if (currentWindowWidth >= 1260) {
            // Init with all options at default setting
            // eslint-disable-next-line new-cap
            void DirectionReveal({
                selector: '.direction-reveal',
                itemSelector: '.direction-reveal__item',
                animationName: 'roll-out',
                animationPostfixEnter: 'enter',
                animationPostfixLeave: 'leave',
                enableTouch: false/* ,
                touchThreshold: 250 */
            });
        }

        scrollAnimations();
    });

    window.addEventListener('load', () => {
        setTimeout(setupSliders, 500);
    });
}

init();

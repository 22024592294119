import { lazyLoad } from '../utils/lazyImage';

const classNames = {
    activeBtn: 'accordion-filter__checkbox--active',
    hiddenItem: 'hidden'
};


/**
 *
 * @param {HTMLElement} container
 *
 * @constructor
 */
export class Filter {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container - Element containing filter options (Could be .filter__tabs or .filter__select)
     */
    constructor(container) {
        if (container) {
            this.dom = {
                container,
                filters: container.querySelectorAll('[data-filter]'),
                targetContainer: document.body.querySelector(container.getAttribute('data-target-container')),
                applyBtn: container.querySelector('[data-action="apply"]'),
                resetBtn: container.querySelector('[data-action="reset"]')
            };

            if (this.dom.targetContainer) {
                if (this.dom.applyBtn) {
                    this.dom.applyBtn.addEventListener('click', e => this.filterApply(e));
                }
                if (this.dom.resetBtn) {
                    this.dom.resetBtn.addEventListener('click', e => this.filterReset(e));
                }
            } else {
                window.console.warn('Can\'t find [data-target-container] on filter container: ', container);
            }
        } else {
            window.console.warn('Filter container missing');
        }
    }

    filterApply(e) {
        const filters = this.dom.filters;
        const filterItems = this.dom.targetContainer.querySelectorAll('[data-filter-value]');
        const filterValues = Filter.getSetValues(this.dom.filters);
        const targetContainer = this.dom.targetContainer;
        let itemResults = 0;

        for (let i = 0; i < filterItems.length; i++) {
            const item = filterItems[i];
            let itemFilterValues = item.getAttribute('data-filter-value').split(',');
            let showItem = false;
            let itemFilterValuesCounter = 0;
            itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

            if (filterValues.length > 0) {
                for (let i = 0; i < filterValues.length; i++) {
                    const value = filterValues[i];
                    const cleanValue = value.trim().toLowerCase();

                    if (itemFilterValues.includes(cleanValue)) {
                        itemResults++;

                        showItem = true;
                    }
                }
            }
            else {
                showItem = true;
            }

            if (!showItem) {
                item.classList.add(classNames.hiddenItem);
            } else {
                item.classList.remove(classNames.hiddenItem);
            }
        }

        if (itemResults < 1) {
            targetContainer.classList.add('filter__no-results');
        } else {
            targetContainer.classList.remove('filter__no-results');
        }

        lazyLoad();
    }

    filterReset(e) {
        for (let i  = 0, len = this.dom.filters.length; i < len; i++) {
            const filter = this.dom.filters[i];
            filter.checked = false;
        }

        this.filterApply();
    }

    static getSetValues(filters) {
        const valArray = [];

        // loop through list of filters
        for (let i  = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            if (filter.checked && filter.value !== '*') { // radio checked?
                valArray.push(filter.value); // if so, add its value to array
            }
        }
        return valArray; // return value of checked radio or undefined if none checked
    }
}

export function setupAccordionFilters(selector = '.accordion-filter') {
    const filterContainers = document.body.querySelectorAll(selector);

    for (let i = 0; i < filterContainers.length; i++) {
        void new Filter(filterContainers[i]);
    }
}

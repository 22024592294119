import Swiper, { Autoplay} from 'swiper';
export const swipers = [];
Swiper.use([Autoplay]);

export function setupLogoSliders() {
    const swiperContainers = document.querySelectorAll('[data-logo-slider]');

    swiperContainers.forEach(swiperContainer => {
        const swiper = new Swiper(swiperContainer, {
            slidesPerView: 2,
            slidesPerGroup: 2,
            speed: 1000,
            loop: true,
            autoplay: {
                delay: parseInt(swiperContainer.getAttribute('data-autoplay')) || 1000,
                disableOnInteraction: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 5
                },
                1500: {
                    slidesPerView: 6,
                    slidesPerGroup: 6
                }
            }
        });

        swiperContainer.addEventListener('mouseenter', () => {
            swiper.autoplay.stop();
        });

        swiperContainer.addEventListener('mouseleave', () => {
            swiper.autoplay.start();
        });

        swipers.push(swiper);
    });
}
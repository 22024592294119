import Flickity from '../../../node_modules/flickity';
import { currentWindowWidth, onWindowResize } from '../utils/windowResize';

/**
 * The Slider class uses the cssAnimateNewHeight function to reveal hidden content.
 * Default element selector is data-action*="slider"
 *
 * @param {HTMLElement} container
 * @constructor
 */
export class Slider {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;

        if (container) {
            const mobileMode = !(currentWindowWidth > 480);
            const slideNo = container.childElementCount;
            this.isGallery = container.parentNode.classList.contains('slider--gallery');
            //this.isLogos = container.parentNode.classList.contains('slider--logo');
            const galleryMode = (this.isGallery && currentWindowWidth >= 1024);

            this.flkty = new Flickity(container, {
                cellAlign: mobileMode ? 'center' : galleryMode ? 'right' : 'left',
                prevNextButtons: false,
                percentPosition: true,
                autoPlay: parseInt(container.getAttribute('data-autoplay')) || false,
                wrapAround: false,
                selectedAttraction: mobileMode ? 0.025 : 0.02,
                friction: 0.20,
                initialIndex: galleryMode ? slideNo - 1 : 0,
                rightToleft: galleryMode,
                pageDots: !!container.dataset.dots,
                draggable: !galleryMode || (galleryMode && currentWindowWidth < 1024),
                lazyLoad: mobileMode ? 1 : 3,
                setGallerySize: true,
                adaptiveHeight: true
            });

            this.bindEvents();
        }
    }

    bindEvents() {
        const scope = this;
        const prevNext = this.container.parentNode.querySelectorAll('.slider__btn');
        const flkty = scope.flkty;

        if (this.isGallery) {

            const textElem = this.container.parentNode.querySelector('.slider__text__content');

            flkty.on('select', index => {
                const newText = flkty.selectedElement.querySelector('.slider__cell__content');
                if (newText) {
                    textElem.innerHTML = newText.innerHTML;
                }
            });
        }

        //Change cell position on resize, when width is smaller than 768px
        onWindowResize(
            function alignSlider() {
                const mobileMode = !(currentWindowWidth > 480);
                const galleryMode = (scope.isGallery && currentWindowWidth >= 1024);

                scope.flkty.option({
                    cellAlign: mobileMode ? 'center' : galleryMode ? 'right' : 'left',
                    rightToleft: galleryMode,
                    wrapAround: mobileMode,
                });
                scope.flkty.resize();
                scope.flkty.reloadCells();
            }
        );

        if (prevNext.length > 1) {
            prevNext[0].addEventListener('click', function (event) {
                scope.flkty.previous();
                enableDisableBtn(prevNext, scope);
            });
            prevNext[1].addEventListener('click', function (event) {
                scope.flkty.next();
                enableDisableBtn(prevNext, scope);
            });
        }
    }
}

function enableDisableBtn(prevNext, scope) {

    if (scope.flkty.selectedIndex === scope.flkty.cells.length - 1) {
        prevNext[1].classList.add('disabled');
    } else {
        prevNext[1].classList.remove('disabled');
    }

    if (scope.flkty.selectedIndex === 0) {
        prevNext[0].classList.add('disabled');
    } else {
        prevNext[0].classList.remove('disabled');
    }
}

export function setupSliders(selector = '[data-action*="slider"]') {
    const sliders = document.body.querySelectorAll(selector);

    for (let i = 0; i < sliders.length; i++) {
        void new Slider(sliders[i]);
    }
}
